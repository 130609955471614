.fixed-plugin {
    position: fixed;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    border-radius: 8px 0 0 8px;
    text-align: center;

    &.fixed-plugin-rtl {
        right: auto;
        left: 0;
        border-radius: 0 8px 8px 0;
        direction: ltr;
        .dropdown.show .dropdown-menu {
            right: auto;
            left: 80px;
            &:before {
                border-left: none;
                border-right: 16px solid rgba(0, 0, 0, 0.2);
                left: -17px;
                right: auto;
            }
            &:after {
                border-left: none;
                border-right: 16px solid #ffffff;
                left: -16px;
                right: auto;
            }
        }
    }

    .dropdown-menu {
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 10px 0;
        margin: 2px 0 0;
        font-size: 14px;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }
}

.fixed-plugin i {
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}

.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 325px;
    border-radius: 10px;
    padding: 10px 20px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}

.adjustments-line,
.header-title {
    width: 100%;
    height: 50px;
    min-height: inherit;
    position: relative;
}

.header-title {
    height: 30px;
    line-height: 25px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.fixed-plugin .dropdown-menu .adjustments-line {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    margin: 0;
    height: stretch;
}

.fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-15%);
    -moz-transform: translateY(-15%);
    -o-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
    top: 8px;
    opacity: 0;
    transform-origin: 0 0;
    display: none;
}

.fixed-plugin .dropdown.show .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(-13%);
    -moz-transform: translateY(-13%);
    -o-transform: translateY(-13%);
    -ms-transform: translateY(-13%);
    transform: translateY(-13%);
    transform-origin: 0 0;
    display: block;
}

.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 84px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -17px;
}

.fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
}

.fixed-plugin .clearfix {
    &::before,
    &::after {
        display: table;
        content: ' ';
    }
    &::after {
        clear: both;
    }
}
