// Scroll bar css

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

*::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #29abe2;
}
// Disable gramerly
// iframe {
//     display: none;
// }

#root {
    position: relative;
    min-height: 100vh;
}
// react toastify css
.toast-container {
    width: 575px;
    margin: 0;
    transform: translateX(-50%);

    .Toastify__toast.material-toast {
        color: #fff;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        border-radius: 4px;
        font-size: 15px;
        padding: 20px 15px;

        .Toastify__toast-body {
            max-width: calc(100% - 50px);
        }

        button.toast-btn {
            background: transparent;
            border: navajowhite;
            color: #fff;
            padding: 0;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 15px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: pointer;
        }

        button.toast-btn svg {
            width: 15px;
            height: 15px;
        }
        button.toast-btn:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }
    .Toastify__toast--default {
        background-color: #00d3ee;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 172, 193, 0.4);
    }
    .Toastify__toast--success {
        background-color: #29abe2;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #29abe240;
    }
    .Toastify__toast--error {
        background-color: #f55a4e;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
    }
    .Toastify__toast--warn {
        background-color: #ffa21a;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    }
    .Toastify__toast--info {
        background-color: #af2cc5;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
    }
}

/* marker css */
.pin {
    width: 100%;
    height: 100%;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 0;
    bottom: 20%;
    border: 2px solid #fff;
}
.pin:after {
    content: '';
    width: 40%;
    height: 40%;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

// leaflet global customize css
.leaflet-draw-toolbar a {
    background-size: 800px 250px !important;
    height: 60px !important;
    width: 60px !important;
}
.leaflet-draw-toolbar a.leaflet-draw-draw-polygon {
    background-position: -87px -95px !important;
}
.leaflet-draw-toolbar a.leaflet-draw-edit-edit {
    background-position: -409px -95px !important;
}
.leaflet-draw-actions {
    left: 60px !important;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
    height: 60px !important;
    line-height: 60px !important;
    font-size: 16px !important;
}
.leaflet-tooltip {
    font-size: 20px;
    margin: 0;
}
// add z index into the tooltip for equipment list page
.equipment-marker .leaflet-marker-pane {
    z-index: 651;
}

// Analysis marker style

.analysisMarker-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

// React table
.ReactTable .rt-tbody .rt-tr .rt-td:last-child {
    text-align: right;
}

// Carousel css

.carousel .slide img {
    height: 100%;
    object-fit: contain;
}

.carousel .control-dots {
    z-index: 99999;
}

.carousel .control-dots .dot {
    transition: background-color 0.25s ease-in;
    border: 2px solid #fff;
    opacity: 1;
    width: 12px;
    height: 12px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    background-color: #29abe2;
}

.carousel .carousel-status {
    right: 6px;
    font-size: 16px;
    font-weight: 500;
}

.carousel .thumb {
    height: 80px;
    border-radius: 5px;
}
.carousel .thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #29abe2;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #2aabe2;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #2aabe2;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 500px;
}

// Map css

.marker-cluster-custom {
    border: 5px solid #2aabe2;
    border-radius: 50%;
    color: #ededed;
}

.marker-cluster-custom div {
    border: 3px solid #fff;
    background-color: #2aabe2;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
}

//Move icon
.move {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyIDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnIGlkPSJtb3ZlIj48Zz48cG9seWdvbiBzdHlsZT0iZmlsbDojMDMwMTA0OyIgcG9pbnRzPSIxOCwyMCAxOCwyNiAyMiwyNiAxNiwzMiAxMCwyNiAxNCwyNiAxNCwyMCAiLz48cG9seWdvbiBzdHlsZT0iZmlsbDojMDMwMTA0OyIgcG9pbnRzPSIxNCwxMiAxNCw2IDEwLDYgMTYsMCAyMiw2IDE4LDYgMTgsMTIgIi8+PHBvbHlnb24gc3R5bGU9ImZpbGw6IzAzMDEwNDsiIHBvaW50cz0iMTIsMTggNiwxOCA2LDIyIDAsMTYgNiwxMCA2LDE0IDEyLDE0ICIvPjxwb2x5Z29uIHN0eWxlPSJmaWxsOiMwMzAxMDQ7IiBwb2ludHM9IjIwLDE0IDI2LDE0IDI2LDEwIDMyLDE2IDI2LDIyIDI2LDE4IDIwLDE4ICIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
}

// Table td white space issue with

td {
    white-space: pre-wrap;
}
